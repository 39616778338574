<template>
  <el-container>
    <el-header class="header">
      <header-top></header-top>
    </el-header>
    <el-main style="overflow: hidden;">
      <div class="parentall" id="box">
        <div
          :style="height"
          class="grid-content bg-purple leftcls"
          id="top"
          style="overflow-y: scroll;overflow-x: hidden"
        >
          <el-row>
            <el-col class="parentitem">
              <div class="parentone">
                <span class="titlequestion">{{titleText}}</span>
              </div>
              <div class="decription" style="margin-top: 20px;">
                时间限制：{{timeLimit}}秒&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>内存限制：{{memoryLimit}}M</span>
              </div>
            </el-col>
            <el-col class="parentitem">
              <div class="titlequestion" style="display: flex">题目描述</div>
              <!-- <div class="decription" id="description" style="margin-top: 20px;"></div> -->
              <div v-html="dataObejcet.description" class="decription" style="margin-top: 20px;"></div>
            </el-col>
            <el-col class="parentitem" v-show="dataObejcet.input">
              <div class="titlequestion" style="display: flex">输入描述</div>
              <!-- <div class="decription" id="input" style="margin-top: 20px;"></div> -->
              <div v-html="dataObejcet.input" class="decription" style="margin-top: 20px;"></div>
            </el-col>
            <el-col class="parentitem">
              <div class="titlequestion" style="display: flex">输出描述</div>
              <!-- <div class="decription" id="output" style="margin-top: 20px;"></div> -->
              <div v-html="dataObejcet.output" class="decription" style="margin-top: 20px;"></div>
            </el-col>
            <el-col class="parentitem">
              <div class="titlequestion">样例</div>
              <div v-show="dataObejcet.sample_input">
                <div class="decription" style="margin-top: 20px;">输入</div>
                <!-- <div class="entercontent" id="sample_input"></div> -->
                <pre v-html="dataObejcet.sample_input" class="entercontent"></pre>
              </div>
              <div class="decription" style="margin-top: 20px;">输出</div>
              <!-- <div class="entercontent" id="sample_output"></div> -->
              <pre v-html="dataObejcet.sample_output" class="entercontent"></pre>
            </el-col>
            <el-col class="parentitem" v-show="dataObejcet.hint">
              <div class="titlequestion" style="display: flex">提示</div>
              <!-- <div class="decription" id="hint" style="margin-top: 20px;margin-bottom: 80px;"></div> -->
              <div v-html="dataObejcet.hint" class="decription" style="margin-top: 20px;"></div>
            </el-col>
          </el-row>
        </div>
        <!-- 中间线 -->
        <div @mousedown="lineMouse($event)" class="line" id="line"></div>
        <!-- 右侧盒子 -->
        <div :style="height" class="rightParent" id="bottom">
          <div class="rightitem" v-show="!showace">
            <div style="cursor:pointer;" v-on:click="gotoresultdetail">
              <el-row class="rightcontent">
                <el-col class="titlequestion">我的提交</el-col>
                <el-col class="secondtitle colcls">
                  提交时间：
                  <span style="margin-left: 10px;">{{submittime}}</span>
                </el-col>
                <el-col class="secondtitle colcls">
                  提交结果：
                  <span
                    :style="'color'+':'+ getStateColor(resultNumber)"
                    style="margin-left: 10px;"
                    v-text="getSubType(resultNumber)"
                  ></span>
                </el-col>
                <el-col class="decription showmessage">
                  提交详情：
                  <div style="margin-left: 10px;">
                    <i class="el-icon-timer"></i>
                    <span style="margin-left: 5px;">{{onedata}}Ms</span>
                  </div>
                  <div style="margin-left: 15px;">
                    <i class="el-icon-coin"></i>
                    <span style="margin-left: 5px;">{{twodata}}M</span>
                  </div>
                  <div style="margin-left: 15px;">
                    <i class="el-icon-document"></i>
                    <span style="margin-left: 5px;">{{threedata}}KB</span>
                  </div>
                </el-col>
              </el-row>
            </div>
            <el-col>
              <el-button
                style="margin-top: 60px;margin-left: 30px"
                type="warning"
                v-on:click="editorCode()"
              >编辑代码</el-button>
            </el-col>
          </div>
          <div
            id="boxtwo"
            style="display: flex;flex-direction: column;background: #181E22;"
            v-show="showace"
          >
            <div class="edittop">
              <el-dropdown @command="setmodemessage">
                <el-button
                  style="background: #516169;border:none; margin-left: 10px;"
                  type="primary"
                >
                  {{currentCode}}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown" style="width: 8%;text-align: center">
                  <el-dropdown-item
                    :command="item"
                    :key="index"
                    v-for="(item, index) in modeArray"
                  >{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <div>
                <el-button
                  style="background: #516169;border:none; margin-right: 15px;"
                  type="primary"
                  v-on:click="closeCode()"
                >
                  <i class="el-icon-share" style="margin-right: 10px;"></i>收起代码编辑区
                </el-button>
              </div>
            </div>
            <div :style="middleheight" class="ace-editor" id="toptwo" ref="ace"></div>
            <div @mousedown="topMouse($event)" id="linetwo" style=" cursor: s-resize;">
              <el-button style="margin: 10px;" type="warning" v-on:click="submitCode()">提交代码</el-button>
            </div>
            <div id="bottomtwo" style="background-color: #3d444c;" v-show="showresult">
              <div id="tipsone" v-show="!getresult">
                <template v-if="judgeNum > 5">
                  <div class="tipclass">判题结果超时，请执行提交代码进行重新判题</div>
                </template>
                <template v-else>
                  <div class="tipclass">您的代码已经保存</div>
                  <div class="tipclass">正在查询结果...</div>
                </template>
              </div>
              <div id="tipstwo" v-show="getresult">
                <div class="tipclass">
                  <span
                    :style="'color'+':'+ getStateColor(currentResultNumber)"
                    v-text="getSubType(currentResultNumber)"
                  ></span>
                </div>
                <div style="height: 310px;overflow-y: auto">
                  <div class="tipclass" id="bianyiText"></div>
                  <div class="tipclass" id="runtimeText"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import { getQuestionById, getLastSubmit, submitSolutionCode, getSubmitHistoryItem } from '@/api/research/question'

import '../../style/common.scss'
import HeaderTop from '@/components/HeaderTop'
import Prism from "prismjs"; //引入插件
import ace from 'ace-builds'
import 'ace-builds/src-noconflict/snippets/javascript'
import 'ace-builds/src-noconflict/snippets/html'
import 'ace-builds/src-noconflict/snippets/java'
import 'ace-builds/src-noconflict/snippets/c_cpp'
import 'ace-builds/src-noconflict/snippets/pascal'
import 'ace-builds/src-noconflict/snippets/ruby'
import 'ace-builds/src-noconflict/snippets/batchfile'
import 'ace-builds/src-noconflict/snippets/python'
import 'ace-builds/src-noconflict/snippets/php'
import 'ace-builds/src-noconflict/snippets/perl'
import 'ace-builds/src-noconflict/snippets/objectivec'
import 'ace-builds/src-noconflict/snippets/scheme'
import 'ace-builds/src-noconflict/snippets/clojure'
import 'ace-builds/src-noconflict/snippets/lua'
import 'ace-builds/src-noconflict/snippets/golang'
import 'ace-builds/src-noconflict/snippets/gobstones'
import 'ace-builds/src-noconflict/snippets/django'
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/theme-clouds_midnight'
import 'ace-builds/src-noconflict/mode-javascript'
import { formatTate } from '@/utils/formatDate'

const modeArray = [
  {
    name: 'C',
    path: 'ace/mode/c_cpp',
    value: 0
  },
  {
    name: 'C++',
    path: 'ace/mode/c_cpp',
    value: 1
  },
  {
    name: 'JavaScript',
    path: 'ace/mode/javascript',
    value: 16
  },
  {
    name: 'Java',
    path: 'ace/mode/java',
    value: 3
  },
  {
    name: 'Python',
    path: 'ace/mode/python',
    value: 6
  },
  {
    name: 'php',
    path: 'ace/mode/php',
    value: 7
  },
  {
    name: 'Pascal',
    path: 'ace/mode/pascal',
    value: 2
  },
  {
    name: 'Ruby',
    path: 'ace/mode/ruby',
    value: 4
  },
  {
    name: 'freeBasic',
    path: 'ace/mode/batchfile',
    value: 11
  },
  {
    name: 'perl',
    path: 'ace/mode/perl',
    value: 8
  },
  {
    name: 'c#',
    path: 'ace/mode/c_cpp',
    value: 9
  },
  {
    name: 'objectiveC',
    path: 'ace/mode/objectivec',
    value: 10
  },
  {
    name: 'scheme',
    path: 'ace/mode/scheme',
    value: 12
  },
  {
    name: 'Lua',
    path: 'ace/mode/lua',
    value: 15
  },
  {
    name: 'Go',
    path: 'ace/mode/golang',
    value: 17
  }
]

export default {
  components: { HeaderTop },
  props: {
    value: String
  },
  mounted () {
    this.aceEditor = ace.edit(this.$refs.ace, {
      fontSize: 20,
      value: this.value ? this.value : '',
      theme: this.themePath,
      mode: this.modePath,
      wrap: this.wrap,
      tabSize: 4
    })
    ace.require('ace/ext/language_tools')
    // 激活自动提示
    this.aceEditor.setOptions({
      enableSnippets: true,
      enableLiveAutocompletion: true,
      enableBasicAutocompletion: true
    })
    this.aceEditor.getSession().on('change', this.change)
  },
  data () {
    return {
      canInterval: true,
      number: 0,
      aceEditor: null,
      toggle: false,
      wrap: true,
      showace: true,
      showresult: false,
      currentCode: 'C++',
      themePath: 'ace/theme/monokai',
      modePath: 'ace/mode/c_cpp',
      modeArray: modeArray,
      currentQuestionNum: 0,
      currentResultNumber: 2,
      currentLanguage: 1,
      questionList: [],
      titleText: '',
      timeLimit: '',
      memoryLimit: '',
      input: '',
      output: '',
      resultNumber: 0,
      submittime: '',
      onedata: '',
      twodata: '',
      threedata: '',
      submitFlag: false,
      questionObject: {},
      getresult: false,
      currentSolutionId: 0,
      beginTime: 0,
      endTime: 0,
      id: 0,
      contest_id: 0,
      lesson_id: 0,
      sampleinputshow: true,
      inputshow: true,
      tipshow: true,
      titleName: '',
      isSubmitCode: false,
      height: {
        height: ''
      },
      middleheight: {
        height: '',
        maxHeight: ''
      },
      footerheight: {
        height: ''
      },
      judgeNum: 0, // 判题次数
      myInterval: null,
      dataObejcet: {}
    }
  },
  created () {
    this.id = this.$route.query.problemID
    this.beginTime = formatTate(new Date())
    this.changeHight()
    this.getQuestionById()
    this.getTheLastCode()
  },
  methods: {
    // 管理端-获取题目详情
    getQuestionById () {
      getQuestionById({
        problem_id: this.id
      }).then(res => {
        if (res.state === 'success') {
          var dataObejcet = res.body[0]
          this.dataObejcet = res.body[0];
          this.titleText = dataObejcet.title
          this.titleName = dataObejcet.title
          this.timeLimit = dataObejcet.time_limit
          this.memoryLimit = dataObejcet.memory_limit
          // document.getElementById(
          //   'description'
          // ).innerHTML = dataObejcet.description.replace(/\n/g, '<br/>')
          // document.getElementById(
          //   'output'
          // ).innerHTML = dataObejcet.output.replace(/\n/g, '<br/>')
          // document.getElementById(
          //   'sample_output'
          // ).innerHTML = dataObejcet.sample_output
          //   .replace(/\n/g, '<br/>')
          //   .replace(/ /g, '&nbsp;')
          // if (dataObejcet.sample_input) {
          //   document.getElementById(
          //     'sample_input'
          //   ).innerHTML = dataObejcet.sample_input
          //     .replace(/\n/g, '<br/>')
          //     .replace(/ /g, '&nbsp;')
          // } else {
          //   this.sampleinputshow = false
          // }
          // if (dataObejcet.input === '<p><br></p>' || !dataObejcet.input) {
          //   this.inputshow = false
          // } else {
          //   document.getElementById(
          //     'input'
          //   ).innerHTML = dataObejcet.input.replace(/\n/g, '<br/>')
          // }
          // // let hint = dataObejcet.hint.replace(/\u200B/g, '')
          // if (hint === '<p><br></p>' || !dataObejcet.hint) {
          //   this.tipshow = false
          // } else {
          //   document.getElementById(
          //     'hint'
          //   ).innerHTML = dataObejcet.hint.replace(/\n/g, '<br/>')
          // }
          this.$nextTick(() => {
            setTimeout(()=> {
              Prism.highlightAll()
            }, 0)
          })
        }
      })
    },

    // 获取最后一次提交代码
    getTheLastCode () {
      getLastSubmit({
        problem_id: this.id
      }).then(res => {
        if (res.state === 'success') {
          this.questionObject = res
          if (res.body.source != null) {
            this.showace = false
            this.submitFlag = true
            this.resultNumber = res.body.result
            this.submittime = res.body.in_date
            this.onedata = res.body.time
            this.twodata = res.body.memory
            this.threedata = res.body.code_length
            this.mySource = res.body.source
          } else {
            this.submitFlag = false
            this.resultNumber = -1
            this.submittime = '暂无提交时间'
            this.onedata = 0
            this.twodata = 0
            this.threedata = 0
          }
        }
      })
    },

    submitCodeText (codeContent) {
      this.isSubmitCode = true
      let enc = new TextEncoder('utf-8')
      submitSolutionCode({
        problem_id: this.id,
        language: this.currentLanguage,
        source: codeContent,
        answer_time: this.beginTime,
        in_date: formatTate(new Date()),
        // time_cost: (this.endTime - this.beginTime) / 1000,
        code_length: enc.encode(codeContent).length
      }).then(res => {
        if (res.state === 'success') {
          this.judgeNum = 0
          this.canInterval = true
          this.currentSolutionId = res.body.solution_id
          this.myInterval = setInterval(this.getResponse, 1000)
        }
      })
    },

    // 获取提交记录
    getResponse () {
      this.judgeNum++ // 执行的判题次数
      if (this.canInterval && this.judgeNum <= 5) {
        getSubmitHistoryItem({
          solution_id: this.currentSolutionId
        }).then(res => {
          if (res.state === 'success') {
            if (
              res.body.result !== 0 &&
              res.body.result !== 1 &&
              res.body.result !== 2 &&
              res.body.result !== 3
            ) {
              this.canInterval = false
              clearInterval(this.myInterval)
              this.getresult = true
              this.currentResultNumber = res.body.result
            }
            if (res.body.compileInfo != null) {
              document.getElementById('bianyiText').innerText = res.body.compileInfo
            }
            if (res.body.runtimeInfo != null) {
              document.getElementById('runtimeText').innerText = res.body.runtimeInfo
            }
          }
        })
      } else {
        this.canInterval = false
        clearInterval(this.myInterval)
      }
    },
    gotoresultdetail () {
      if (this.submitFlag) {
        this.questionObject.body.problemName = this.titleName
        this.$router.push({
          path: '/research/question-subDetails',
          query: {
            resultData: JSON.stringify(this.questionObject.body),
            role: 'admin'
          }
        })
      } else {
        window.$msg('提交完题目在查看结果', 2)
      }
    },

    editorCode () {
      this.showace = true
      this.aceEditor.setValue(this.mySource)
      this.$forceUpdate()
      document.getElementById('top').style.width = '50%'
      document.getElementById('bottom').style.width = '50%'
    },
    closeCode () {
      if (this.isSubmitCode) {
        this.isSubmitCode = false
        this.getTheLastCode()
      }
      this.showace = false
      document.getElementById('top').style.width = '50%'
      document.getElementById('bottom').style.width = '50%'
    },

    lineMouse (e) {
      if (!this.showace) {
        return false
      }
      let oBox = document.getElementById('box')
      let oTop = document.getElementById('top')
      let oBottom = document.getElementById('bottom')
      let oLine = document.getElementById('line')
      var disX = (e || event).clientX

      oLine.left = oLine.offsetLeft
      document.onmousemove = function () {
        var iT = oLine.left + ((e || event).clientX - disX)
        var e = e || window.event
        var maxT = oBox.clientWidth - oLine.offsetWidth
        oLine.style.margin = 0
        iT < 0 && (iT = 0)
        iT > maxT && (iT = maxT)
        oLine.style.left = oTop.style.width = iT + 'px'
        oBottom.style.width = oBox.clientWidth - iT + 'px'
        return false
      }
      document.onmouseup = function () {
        document.onmousemove = null
        document.onmouseup = null
        oLine.releaseCapture && oLine.releaseCapture()
      }
      oLine.setCapture && oLine.setCapture()
      return false
    },
    topMouse (e) {
      let oBox = document.getElementById('boxtwo')
      let oTop = document.getElementById('toptwo')
      let oBottom = document.getElementById('bottomtwo')
      let oLine = document.getElementById('linetwo')
      var disY = (e || event).clientY
      oLine.top = oLine.offsetTop
      document.onmousemove = function () {
        var iT = oLine.top + ((e || event).clientY - disY)
        var e = e || window.event
        var maxT = oBox.clientHeight - oLine.offsetHeight
        oLine.style.margin = 0
        iT < 0 && (iT = 0)
        iT > maxT && (iT = maxT)
        oLine.style.top = oTop.style.height = iT + 'px'
        oBottom.style.height = oBox.clientHeight - iT + 'px'
        return false
      }
      document.onmouseup = function () {
        document.onmousemove = null
        document.onmouseup = null
        oLine.releaseCapture && oLine.releaseCapture()
      }
      oLine.setCapture && oLine.setCapture()
      return false
    },
    submitCode () {
      if (this.aceEditor.getValue() === '') {
        this.$message('提交代码不能为空')
        return false
      } else {
        this.submitCodeText(this.aceEditor.getValue())
      }
      if (!this.showresult) {
        this.showresult = true
        this.footerheight.height = window.screen.height / 3 + 'px'
        this.middleheight.height = window.screen.height - 650 + 'px'
      }
    },
    changeHight () {
      this.height.height = window.screen.height - 205 + 'px'
      this.middleheight.maxHeight = window.screen.height - 310 + 'px'
      this.middleheight.height = window.screen.height - 310 + 'px'
    },

    change () {
      this.$emit('input', this.aceEditor.getSession().getValue())
    },

    setmodemessage (codeItem) {
      this.currentCode = codeItem.name
      this.currentLanguage = codeItem.value
      this.aceEditor.getSession().setMode(codeItem.path)
    },

    getSubType (state) {
      switch (state) {
        case 0:
          return '等待'
        case 1:
          return '等待重判'
        case 2:
          return '编译中'
        case 3:
          return '运行并评判'
        case 4:
          return '正确'
        case 5:
          return '格式错误'
        case 6:
          return '答案错误'
        case 7:
          return '时间超限'
        case 8:
          return '内存超限'
        case 9:
          return '输出超限'
        case 10:
          return '运行错误'
        case 11:
          return '编译错误'
        case 12:
          return '编译成功'
        case 13:
          return '运行完成'
        default:
          return '未知状态'
      }
    },

    getStateColor (state) {
      switch (state) {
        case 4:
          return '#4DA54D'
        case 12:
          return '#4DA54D'
        case 13:
          return '#4DA54D'
        case 5:
          return '#CE433F'
        case 6:
          return '#CE433F'
        case 10:
          return '#CE433F'
        case 11:
          return '#CE433F'
        default:
          return '#EB9316'
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.tipclass {
  font-size: 20px;
  color: #25bb9b;
  padding: 5px;
}

.header {
  width: 100%;
  display: flex;
  padding-left: 15px;
  align-items: center;
  background: #212121;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
}

.parentone {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 0px;
}

.parenttwo {
  color: #f5c319;
  font-size: 16px;
  margin-left: 50px;
}

.topqes {
  margin-left: 10px;
  margin-right: 50px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple {
  background: #f8f9fa;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.parentitem {
  width: 96%;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #ffffff;
  margin: 15px;
  padding-left: 30px;
}

.decription {
  line-height: 26px;
}

.viticalline {
  width: 4px;
  background-color: #f5c319;
  height: 20px;
  margin-right: 15px;
}

.entercontent {
  width: 90%;
  border: solid 1px #a5a5a5;
  margin-top: 20px;
  padding: 15px;
}

.ace-editor {
  width: 100%;
  min-width: 30%;
  min-height: 150px;
  overflow-y: hidden;
}

.line {
  width: 15px;
  background: #e8e8e8;
  cursor: w-resize;
  overflow: hidden;
}

.leftcls {
  width: 50%;
  min-width: 30%;
}

.parentall {
  display: flex;
  position: relative;
}

.el-dropdown-link {
  cursor: pointer;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.questionlist {
  width: 20%;
  margin-left: 20px;
  padding: 15px;
}

.rightParent {
  width: 50%;
  min-width: 30%;
}

.edittop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.editbtn {
  width: 120px;
  height: 50px;
  line-height: 50px;
  background: rgba(245, 195, 25, 1);
  border-radius: 1px;
  margin: 30px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
}

.submmitbtn {
  width: 120px;
  height: 40px;
  line-height: 40px;
  background: rgba(245, 195, 25, 1);
  border-radius: 1px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  margin-top: 3px;
  margin-right: 25px;
  cursor: default;
}

.rightitem {
  width: 90%;
  height: 180px;
  margin: 20px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.09);
  border-radius: 2px;
}

.rightcontent {
  padding: 25px;
}

.colcls {
  margin-top: 15px;
}

.showmessage {
  margin-top: 15px;
  display: flex;
}

/deep/ .el-main {
  padding: 0;
}
</style>
