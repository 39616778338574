var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c("el-header", { staticClass: "header" }, [_c("header-top")], 1),
      _c("el-main", { staticStyle: { overflow: "hidden" } }, [
        _c("div", { staticClass: "parentall", attrs: { id: "box" } }, [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple leftcls",
              staticStyle: { "overflow-y": "scroll", "overflow-x": "hidden" },
              style: _vm.height,
              attrs: { id: "top" },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { staticClass: "parentitem" }, [
                    _c("div", { staticClass: "parentone" }, [
                      _c("span", { staticClass: "titlequestion" }, [
                        _vm._v(_vm._s(_vm.titleText)),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "decription",
                        staticStyle: { "margin-top": "20px" },
                      },
                      [
                        _vm._v(
                          " 时间限制：" + _vm._s(_vm.timeLimit) + "秒        "
                        ),
                        _c("span", [
                          _vm._v("内存限制：" + _vm._s(_vm.memoryLimit) + "M"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("el-col", { staticClass: "parentitem" }, [
                    _c(
                      "div",
                      {
                        staticClass: "titlequestion",
                        staticStyle: { display: "flex" },
                      },
                      [_vm._v("题目描述")]
                    ),
                    _c("div", {
                      staticClass: "decription",
                      staticStyle: { "margin-top": "20px" },
                      domProps: {
                        innerHTML: _vm._s(_vm.dataObejcet.description),
                      },
                    }),
                  ]),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dataObejcet.input,
                          expression: "dataObejcet.input",
                        },
                      ],
                      staticClass: "parentitem",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "titlequestion",
                          staticStyle: { display: "flex" },
                        },
                        [_vm._v("输入描述")]
                      ),
                      _c("div", {
                        staticClass: "decription",
                        staticStyle: { "margin-top": "20px" },
                        domProps: { innerHTML: _vm._s(_vm.dataObejcet.input) },
                      }),
                    ]
                  ),
                  _c("el-col", { staticClass: "parentitem" }, [
                    _c(
                      "div",
                      {
                        staticClass: "titlequestion",
                        staticStyle: { display: "flex" },
                      },
                      [_vm._v("输出描述")]
                    ),
                    _c("div", {
                      staticClass: "decription",
                      staticStyle: { "margin-top": "20px" },
                      domProps: { innerHTML: _vm._s(_vm.dataObejcet.output) },
                    }),
                  ]),
                  _c("el-col", { staticClass: "parentitem" }, [
                    _c("div", { staticClass: "titlequestion" }, [
                      _vm._v("样例"),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.dataObejcet.sample_input,
                            expression: "dataObejcet.sample_input",
                          },
                        ],
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "decription",
                            staticStyle: { "margin-top": "20px" },
                          },
                          [_vm._v("输入")]
                        ),
                        _c("pre", {
                          staticClass: "entercontent",
                          domProps: {
                            innerHTML: _vm._s(_vm.dataObejcet.sample_input),
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "decription",
                        staticStyle: { "margin-top": "20px" },
                      },
                      [_vm._v("输出")]
                    ),
                    _c("pre", {
                      staticClass: "entercontent",
                      domProps: {
                        innerHTML: _vm._s(_vm.dataObejcet.sample_output),
                      },
                    }),
                  ]),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dataObejcet.hint,
                          expression: "dataObejcet.hint",
                        },
                      ],
                      staticClass: "parentitem",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "titlequestion",
                          staticStyle: { display: "flex" },
                        },
                        [_vm._v("提示")]
                      ),
                      _c("div", {
                        staticClass: "decription",
                        staticStyle: { "margin-top": "20px" },
                        domProps: { innerHTML: _vm._s(_vm.dataObejcet.hint) },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", {
            staticClass: "line",
            attrs: { id: "line" },
            on: {
              mousedown: function ($event) {
                return _vm.lineMouse($event)
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "rightParent",
              style: _vm.height,
              attrs: { id: "bottom" },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showace,
                      expression: "!showace",
                    },
                  ],
                  staticClass: "rightitem",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.gotoresultdetail },
                    },
                    [
                      _c(
                        "el-row",
                        { staticClass: "rightcontent" },
                        [
                          _c("el-col", { staticClass: "titlequestion" }, [
                            _vm._v("我的提交"),
                          ]),
                          _c("el-col", { staticClass: "secondtitle colcls" }, [
                            _vm._v(" 提交时间： "),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "10px" } },
                              [_vm._v(_vm._s(_vm.submittime))]
                            ),
                          ]),
                          _c("el-col", { staticClass: "secondtitle colcls" }, [
                            _vm._v(" 提交结果： "),
                            _c("span", {
                              staticStyle: { "margin-left": "10px" },
                              style:
                                "color" +
                                ":" +
                                _vm.getStateColor(_vm.resultNumber),
                              domProps: {
                                textContent: _vm._s(
                                  _vm.getSubType(_vm.resultNumber)
                                ),
                              },
                            }),
                          ]),
                          _c(
                            "el-col",
                            { staticClass: "decription showmessage" },
                            [
                              _vm._v(" 提交详情： "),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "10px" } },
                                [
                                  _c("i", { staticClass: "el-icon-timer" }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "5px" } },
                                    [_vm._v(_vm._s(_vm.onedata) + "Ms")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "15px" } },
                                [
                                  _c("i", { staticClass: "el-icon-coin" }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "5px" } },
                                    [_vm._v(_vm._s(_vm.twodata) + "M")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "15px" } },
                                [
                                  _c("i", { staticClass: "el-icon-document" }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "5px" } },
                                    [_vm._v(_vm._s(_vm.threedata) + "KB")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-top": "60px",
                            "margin-left": "30px",
                          },
                          attrs: { type: "warning" },
                          on: {
                            click: function ($event) {
                              return _vm.editorCode()
                            },
                          },
                        },
                        [_vm._v("编辑代码")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showace,
                      expression: "showace",
                    },
                  ],
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    background: "#181E22",
                  },
                  attrs: { id: "boxtwo" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "edittop" },
                    [
                      _c(
                        "el-dropdown",
                        { on: { command: _vm.setmodemessage } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                background: "#516169",
                                border: "none",
                                "margin-left": "10px",
                              },
                              attrs: { type: "primary" },
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.currentCode) + " "),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]
                          ),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticStyle: {
                                width: "8%",
                                "text-align": "center",
                              },
                              attrs: { slot: "dropdown" },
                              slot: "dropdown",
                            },
                            _vm._l(_vm.modeArray, function (item, index) {
                              return _c(
                                "el-dropdown-item",
                                { key: index, attrs: { command: item } },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                background: "#516169",
                                border: "none",
                                "margin-right": "15px",
                              },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeCode()
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-share",
                                staticStyle: { "margin-right": "10px" },
                              }),
                              _vm._v("收起代码编辑区 "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", {
                    ref: "ace",
                    staticClass: "ace-editor",
                    style: _vm.middleheight,
                    attrs: { id: "toptwo" },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: { cursor: "s-resize" },
                      attrs: { id: "linetwo" },
                      on: {
                        mousedown: function ($event) {
                          return _vm.topMouse($event)
                        },
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { margin: "10px" },
                          attrs: { type: "warning" },
                          on: {
                            click: function ($event) {
                              return _vm.submitCode()
                            },
                          },
                        },
                        [_vm._v("提交代码")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showresult,
                          expression: "showresult",
                        },
                      ],
                      staticStyle: { "background-color": "#3d444c" },
                      attrs: { id: "bottomtwo" },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.getresult,
                              expression: "!getresult",
                            },
                          ],
                          attrs: { id: "tipsone" },
                        },
                        [
                          _vm.judgeNum > 5
                            ? [
                                _c("div", { staticClass: "tipclass" }, [
                                  _vm._v(
                                    "判题结果超时，请执行提交代码进行重新判题"
                                  ),
                                ]),
                              ]
                            : [
                                _c("div", { staticClass: "tipclass" }, [
                                  _vm._v("您的代码已经保存"),
                                ]),
                                _c("div", { staticClass: "tipclass" }, [
                                  _vm._v("正在查询结果..."),
                                ]),
                              ],
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.getresult,
                              expression: "getresult",
                            },
                          ],
                          attrs: { id: "tipstwo" },
                        },
                        [
                          _c("div", { staticClass: "tipclass" }, [
                            _c("span", {
                              style:
                                "color" +
                                ":" +
                                _vm.getStateColor(_vm.currentResultNumber),
                              domProps: {
                                textContent: _vm._s(
                                  _vm.getSubType(_vm.currentResultNumber)
                                ),
                              },
                            }),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "310px",
                                "overflow-y": "auto",
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "tipclass",
                                attrs: { id: "bianyiText" },
                              }),
                              _c("div", {
                                staticClass: "tipclass",
                                attrs: { id: "runtimeText" },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }